<template>
    <svg class="fill-current" :class="colour">
        <use :xlink:href="iconsPath+'#'+icon"></use>
    </svg>
</template>

<script setup>
import iconsPath from '@/../../images/icons-project_type.svg';
import { computed } from 'vue';

const props = defineProps({
    icon: {
        type: String,
        default: null,
    },
});

const icon = computed(() => {
    return {
        // these keys should match the PHP enums
        'FIRE': 'fire',
        'STORM': 'storm',
        'THEFT': 'theft',
        'VEHICLE': 'vehicle',
        'WATER': 'water',
        'CONSTRUCTION': 'construction',
    }[props.icon] || 'other';
});

const colour = computed(() => {
    return {
        'FIRE': 'text-eco-project-type-fire',
        'STORM': 'text-eco-project-type-storm',
        'THEFT': 'text-eco-project-type-theft',
        'VEHICLE': 'text-eco-project-type-vehicle',
        'WATER': 'text-eco-project-type-water',
        'CONSTRUCTION': 'text-eco-project-type-construction',
    }[props.icon] || 'text-eco-project-type-other';
});
</script>
